/* STYLE FOR COMMON COMPONENTS */
#scrapper div.filter-container,
div.advanced-filters,
main.scrappertable-container,
div.buttons-container {
  margin: 0 100px;
}

#scrapper table tbody {
  color: #131212a9;
}

#scrapper {
  font-family: "Lexend Deca", sans-serif;
}

#scrapper h1 {
  text-align: center;
  margin: 20px 100px;
}

#scrapper div.filter-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #8cc63f;
  border-radius: 10px;
  height: 50px;
}

#scrapper div.filter-container select.form-select {
  width: auto;
}

#scrapper table {
  margin-top: 30px;
  width: 100%;
}

/* maring top for buttons container */
#scrapper div.buttons-container {
  display: flex;
  justify-content: center;
}

#scrapper div.buttons-container button {
  margin: 25px;
  border-radius: 0;
  border: none;
  border-bottom: 0.1px solid #8cc63f;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#scrapper div.buttons-container button.btn:hover {
  background-color: #8cc63f;
  border: none;
  margin: none;
  color: white;
}

#scrapper .confirmbtn {
  background-color: #8cc63f;
  color: white;
}

/* makes the table to have a horizontal scroll */
#scrapper .table-wrapper {
  overflow-x: auto;
  overflow-y: scroll;
  width: 100%;
  height: 60vh; /* Set a fixed height for the table wrapper */
}
#scrapper table {
  border-collapse: collapse;
  width: 100%;
}
#scrapper th,
#scrapper td {
  padding: 8px 20px;
  text-align: center;
  border: 1px solid #ddd;
  vertical-align: middle;
}
#scrapper th {
  background-color: #f2f2f2;
}
#scrapper table tbody h1 {
  text-align: center;
}

#scrapper table span {
  cursor: pointer;
}

#scrapper table td:nth-child(1):hover {
  /* margin-left: 20px; */
  animation: horizontal-shaking 0.5s infinite !important;
}

#scrapper .filter-container > button {
  border: none;
  border-radius: 5px;
  padding: 6px;
  width: 100px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;
  transition: all 0.2s ease-in;
}
#scrapper .filter-container select {
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  cursor: pointer;
}

#scrapper .filter-container > button:hover {
  background-color: #537526;
  color: #fff;
  transition: all 0.2s ease-in;
}

#scrapper .filter-container > button:hover,
#scrapper .filter-container > select:hover {
  transform: translateY(-1px);
  transition: all 0.2s ease-in;
}

#scrapper button#upload input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

#scrapper #uploadFile input {
  display: none;
}

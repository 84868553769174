#Navigation img{
    margin-left: 10px;
    width: 69px;
}

#Navigation li a{
    color: black !important;
    font-family: 'Lexend Deca', sans-serif !important;
    text-decoration: none;
    padding-right: 16px;
}

#Navigation li a:hover{
    text-decoration: underline;
}

#Navigation #contact-btn{
    display: none;
}

#Navigation ul{
    justify-content: flex-end;
}

@media (max-width: 1000px) {
    
    #Navigation div:first-child{
        flex-direction: row-reverse;
    }

    #Navigation #contact-btn{
        display:block;
        background-color: #bfd732;
        border-radius: 40px;
        margin: auto;
        margin-top: 30px;
        min-width: 40%;
    }

    #Navigation div li {
        width: 100%;
        padding: 10px;
    }
    
    #Navigation li a{
        border-bottom: 2px solid gray;
        width: 100%;
        display: block;
        padding: 10px;
    }
}
